import { Club } from 'models/Club';

import hosts from '../hosts';
import request from '..';

export class ClubController {
  static getInfo = async ({ id, slug, channel, lang }) => {
    const club = await request({
      path: `/v1/clubs/${id || slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        channel,
      },
    });
    return new Club(club);
  };

  static getGallery = ({ id, slug }) => {
    return request({
      path: `/v1/clubs/${id || slug}/gallery`,
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static getFollowers = ({ id, slug }) => {
    return request({
      path: `/v1/clubs/${id || slug}/followers`,
      baseUrl: hosts.EVENTS_SERVICE,
    });
  };

  static getSeatsioKeys = ({ id }) => {
    return request({
      path: `/v1/venues/${id}`,
      baseUrl: hosts.TABLE_MANAGEMENT,
    });
  };

  static getAll = async params => {
    const clubs = await request({
      path: `/v1/clubs`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: params,
    });

    return clubs.map(club => new Club(club));
  };

  static getAllByCategory = async ({ slug, ...params }) => {
    const clubs = await request({
      path: `/v1/clubs/categories/${slug}`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...params,
      },
    });

    return clubs.map(club => new Club(club));
  };
}
